import renterIcon from '@src/assets/svg/renterIcon';
import agentIcon from '@src/assets/svg/agentIcon';
import ownerIcon from '@src/assets/svg/ownerIcon';

const ZEME_USERS_ROLE = {
  RENTER: 'renter',
  AGENT: 'agent',
  LANDLORD: 'landlord',
};

const REGEX = {
  onlyDigit: /^\d+$/,
};

const USERS_ROLE_WITH_DETAIL = {
  [ZEME_USERS_ROLE.RENTER]: {
    label: `${ZEME_USERS_ROLE.RENTER.charAt(
      0
    ).toUpperCase()}${ZEME_USERS_ROLE.RENTER.substring(1)}`,
    value: ZEME_USERS_ROLE.RENTER,
    subLabel: '(Or Guarantor)',
    image: renterIcon,
    isSelected: false,
  },
  [ZEME_USERS_ROLE.AGENT]: {
    label: `${ZEME_USERS_ROLE.AGENT.charAt(
      0
    ).toUpperCase()}${ZEME_USERS_ROLE.AGENT.substring(1)}`,
    image: agentIcon,
    subLabel: '',
    value: ZEME_USERS_ROLE.AGENT,
    isSelected: false,
  },
  [ZEME_USERS_ROLE.LANDLORD]: {
    label: `${ZEME_USERS_ROLE.LANDLORD.charAt(
      0
    ).toUpperCase()}${ZEME_USERS_ROLE.LANDLORD.substring(1)}`,
    value: ZEME_USERS_ROLE.LANDLORD,
    subLabel: '(Property Manager)',
    image: ownerIcon,
    isSelected: false,
  },
};
const DATE_FORMAT = 'MM-dd-yyyy';

const PLAN_FREQUENCY = { monthly: 'MONTH', yearly: 'YEARLY' }; // plan frequencies
const PLAN_TYPES = {
  perUnit: 'PER_UNIT',
  standard: 'STANDARD',
  custom: 'CUSTOM',
};

const SSO_TYPES = {
  GOOGLE: 'GOOGLE',
  APPLE: 'APPLE',
};

/* eslint-disable no-unused-vars */
export enum CANCEL_SUBSCRIPTION_ENUM {
  TOO_EXPENSIVE = 'TOO_EXPENSIVE',
  ANOTHER_SOFTWARE = 'ANOTHER_SOFTWARE',
  BUSINESS_CLOSING = 'BUSINESS_CLOSING',
  NOT_ENOUGH_SUPPORT = 'NOT_ENOUGH_SUPPORT',
  OTHER_REASON = 'OTHER_REASON',
}

export enum SUBSCRIPTION_STATUS_ENUM {
  ACTIVE = 'ACTIVE',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

const CANCEL_SUBSCRIPTION_REASONS = [
  {
    label: 'Too Expensive',
    value: CANCEL_SUBSCRIPTION_ENUM.TOO_EXPENSIVE,
  },
  {
    label: 'Another Software',
    value: CANCEL_SUBSCRIPTION_ENUM.ANOTHER_SOFTWARE,
  },
  {
    label: 'Business Closing',
    value: CANCEL_SUBSCRIPTION_ENUM.BUSINESS_CLOSING,
  },
  {
    label: 'Not Enough Support',
    value: CANCEL_SUBSCRIPTION_ENUM.NOT_ENOUGH_SUPPORT,
  },
  {
    label: 'Other Reason',
    value: CANCEL_SUBSCRIPTION_ENUM.OTHER_REASON,
  },
];

export enum ZEME_PAYMENT_METHODS {
  GOOGLE_PAY = 'GOOGLE_PAY',
  APPLE_PAY = 'APPLE_PAY',
  CARD_PAYMENT = 'TOKEN',
}

export {
  ZEME_USERS_ROLE,
  REGEX,
  USERS_ROLE_WITH_DETAIL,
  DATE_FORMAT,
  PLAN_FREQUENCY,
  PLAN_TYPES,
  CANCEL_SUBSCRIPTION_REASONS,
  SSO_TYPES,
};
