import { ZEME_USER_DATA } from '@src/constants/localStorage';
import { API_BASE_URL } from '@src/constants/urls';
import { SessionObj } from '@src/interfaces/utils/localStorage';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

/**
 * Determines whether a Unix-JWT datetime has expired.
 * @param token - The JWT token to decode and check expiration.
 * @returns True if the token is expired, otherwise false.
 */
function isTokenExpired(token: string): boolean {
  try {
    const decoded: { exp: number } = jwtDecode(token);
    return Date.now() >= decoded.exp * 1000;
  } catch (error) {
    console.error(`Error decoding token: ${error}`);
    return true; // Assume expired if there's an error
  }
}

/**
 * Get an item from local storage.
 * @param key - The key of the item to retrieve.
 * @returns The retrieved item of type T, or null if the item doesn't exist or an error occurs.
 */
export function getItem<T>(key: string): T | null {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue !== null) {
      return isSerializedJSON(serializedValue)
        ? JSON.parse(serializedValue)
        : serializedValue;
    }
  } catch (error) {
    console.error(`Error getting item from local storage: ${error}`);
  }
  return null;
}

/**
 * Set an item in local storage.
 * @param key - The key of the item to set.
 * @param value - The value of the item to set, either an object or a string.
 */
export function setItem(key: string, value: object | string): void {
  try {
    const serializedValue =
      typeof value === 'object' ? JSON.stringify(value) : value;
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error(`Error setting item in local storage: ${error}`);
  }
}

/**
 * Removes Item from Local Storage
 * @param key
 */
export function removeItem(key: string): void {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing item from local storage: ${error}`);
  }
}

/**
 * Checks User is Loggedin or Not
 * @returns A boolean indicating whether the user is logged in or not.
 */
export function isLoggedIn(): boolean {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.access_token
      ? Boolean(storedValue.access_token)
      : false;
  } catch (error) {
    console.error(`Error checking if user is logged in: ${error}`);
    return false;
  }
}

/**
 * Returns access token
 */
export function getAccessToken(): string | null {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.access_token ? storedValue?.access_token : null;
  } catch (error) {
    console.error(`Error checking if user is logged in: ${error}`);
    return null;
  }
}

/**
 * Returns access token
 */
export function getRefreshToken(): string | null {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.refresh_token ? storedValue?.refresh_token : null;
  } catch (error) {
    console.error(`Error checking if user is logged in: ${error}`);
    return null;
  }
}

/**
 * Checks if a user has a valid (non-expired) token
 * Usually, other views do this on specific actions, but add-property requires this util.
 * @returns A boolean indicating whether the user's tokens are valid (non-expired) or not.
 */
export async function areTokensValid(): Promise<boolean> {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    const accessToken = storedValue?.access_token
      ? storedValue?.access_token
      : null;
    const refreshToken = storedValue?.refresh_token
      ? storedValue?.refresh_token
      : null;
    if (!accessToken || !refreshToken) {
      return false;
    }

    if (isTokenExpired(accessToken)) {
      if (isTokenExpired(refreshToken)) {
        localStorage.clear();
        return false;
      } else {
        try {
          // Fetch the fresh tokens
          const rs = await axios.post(`${API_BASE_URL}/users/refresh/`, {
            refresh_token: getRefreshToken(),
          });
          const userData = getItem<SessionObj | null>(ZEME_USER_DATA);
          if (userData != null) {
            userData.refresh_token = rs.data.refresh_token;
            userData.access_token = rs.data.access_token;
            setItem(ZEME_USER_DATA, userData);
            return true;
          } else {
            return false;
          }
        } catch {
          localStorage.clear();
          return false;
        }
      }
    }
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Checks if Valid JSON
 * @param value
 * @returns A boolean value indication whether the string is serialisable
 */
function isSerializedJSON(value: string): boolean {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Retrieves logged in users id
 * @returns User Id of logged in user
 */
export function getUserId(): string | null {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.id ? storedValue?.id : null;
  } catch (error) {
    console.error(`Error getting logged in user id: ${error}`);
    return null;
  }
}

/**
 * Retrieves logged in users id
 * @returns User Id of logged in user
 */
export function getRelatedAgentId(): string | null {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.related_agent_id ? storedValue?.related_agent_id : null;
  } catch (error) {
    console.error(`Error getting logged in user id: ${error}`);
    return null;
  }
}

/**
 * Retrieves logged in users type renter|agent
 * @returns Account type of logged in user
 */
export function getUserType(): string {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.account_type
      ? storedValue?.account_type?.toLowerCase()
      : '';
  } catch (error) {
    console.error(`Error getting logged in user id: ${error}`);
    return '';
  }
}

/**
 * Retrieves the verification status of the user.
 *
 * @return {boolean} The verification status of the user. Returns `true` if the user is verified, `false` otherwise.
 */
export function getUserVerified(): boolean {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.verified ? storedValue?.verified : false;
  } catch (error) {
    console.error(`Error getting logged in user id: ${error}`);
    return false;
  }
}

/**
 * Retrieves whether the user has ever subscribed.
 *
 * @return {boolean} The value indicating whether the user has ever subscribed.
 */
export function getUserEverSubscribed(): boolean {
  try {
    const storedValue = getItem<SessionObj | null>(ZEME_USER_DATA);
    return storedValue?.ever_subscribed ? storedValue?.ever_subscribed : false;
  } catch (error) {
    console.error(`Error getting logged in user id: ${error}`);
    return false;
  }
}

export function getSavedPropertyIds(): string[] {
  try {
    const isSavedPropertyId: string[] = getItem('savedPropertyId') ?? [];
    return isSavedPropertyId;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const setSavedPropertyId = (propertyId: string) => {
  try {
    const getPropertyIds = getSavedPropertyIds();
    if (!getPropertyIds.includes(propertyId)) {
      getPropertyIds.push(propertyId);
    }
    const savedPropertyId = JSON.stringify(getPropertyIds);
    setItem('savedPropertyId', savedPropertyId);
  } catch (error) {
    console.error(error);
  }
};

export const removeSavedPropertyId = (propertyId: string) => {
  try {
    const getPropertyIds = getSavedPropertyIds();

    if (getPropertyIds.includes(propertyId)) {
      const removedIds = getPropertyIds.filter((item) => item !== propertyId);
      const savedPropertyId = JSON.stringify(removedIds);
      setItem('savedPropertyId', savedPropertyId);
    }
  } catch (error) {
    console.error(error);
  }
};
